import $ from 'jquery'
import './bem'

import ComponentFactory from './component-factory'

export default class Compiler {
	static run(components, context = $('body')) {
		/**
		 * Dit stuk geeft een melding wanneer de environment nog op development staat
		 * Dit hoeft niet weggehaald te worden als gecompiled wordt als productie,
		 * hier zorgt de compiler voor.
		 */
		if (process.env.NODE_ENV === 'development') {
			// eslint-disable-next-line no-console
			console.log(
				'%cdevelopment build',
				'font-size:2em;width:100%;color:#A3303D;padding:0 1em;',
			)
		}

		/**
		 * Initiate thumb script
		 */
		// thumb()

		Object.keys(components).forEach((component) => {
			context.find(`.${components[component].title}`).each(function (index) {
				if (typeof $(this).attr('data-no-js') === 'undefined') {
					ComponentFactory.create(components[component], $(this), index === 0)
				}
			})
		})
	}
}
