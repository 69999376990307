import Block from '../block'

export default class AddressPopup extends Block {
	static title = 'address-popup'

	constructor($el) {
		super($el)

		$(document).on('address:open', () => {
			this.$el.get(0).show()
		})

		this.$el.findElement('form').on('submit', () => {
			this.$el.findElement('button').prop('disabled', true)
		})
	}
}
