import 'core-js/stable'
import 'regenerator-runtime/runtime'

import 'scripts/bem'

// load the stylin
import './style.css'
import 'intl-tel-input/build/css/intlTelInput.css'

// load the common blocks
import * as blocks from './components'
import * as shoppingcart from '../../_shoppingcart/src'

// load the run script
import Compiler from 'scripts/run.js'

Compiler.run({ ...blocks, ...shoppingcart })
