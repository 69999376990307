import Block from '../block'

import * as blocks from '../'
import Compiler from 'scripts/run.js'

export default class ShoppingcartPopup extends Block {
	static title = 'shoppingcart-popup'

	constructor($el) {
		super($el)

		this.drawer = this.$el.findElement('drawer')
		this.close = this.$el.findElement('close')

		this.close.on('click', () => {
			this.drawer.get(0).hide()
		})

		$(document).on('shoppingcart:open', () => {
			this.drawer.get(0).show()
		})

		$(document).on('product:add', (index, data) => {
			this.$el.findElement('products').html(data.html)
			this.$el.findElement('count').text(data.count)

			this.drawer.get(0).show()

			Compiler.run(blocks, this.$el)
		})

		$(document).on('product:remove', (index, data) => {
			this.$el.findElement('count').text(data.count)
		})

		$(document).on('product:update', (index, data) => {
			this.$el.findElement('count').text(data.count)
		})
	}
}
